.scrollToTopIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(215, 215, 255);
    border-radius: 2px;
    width: 30px;
    height: 30px;
    background-color: white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    transition: 0.3s;
    cursor: pointer;
}