 

@import "./root.scss";
@import "./common.scss";
@import "./media.scss";
@import "./fonts.scss";

// optional link start here 
.link-txt {
    text-decoration: none;
    color: #000;
    &:hover{
        color: #000;
    }
}
.transparent{
    background: transparent;
}
// optional link end here 


a {
    color: transparent;
    text-decoration: underline;
}