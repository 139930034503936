// pops Font
@font-face {
    font-family: pop-Medium;
    src: url("../Assets/Fonts/Poppins-Medium.ttf");
}

@font-face {
    font-family: pop-MediumBold;
    src: url("../Assets/Fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: pop-Regular;
    src: url("../Assets/Fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: pop-Bold;
    src: url("../Assets/Fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: pop-black;
    src: url("../Assets/Fonts/Poppins-Black.ttf");
}
.fontMedium {
    font-family: "pop-Medium";
}

.fontBlack {
    font-family: "pop-black";
}


.fontMediumBold {
    font-family: "pop-MediumBold";   
    font-weight: 200 !important;
}

@media (min-width: 961px) {
    .fontMediumBold {
        font-family: "pop-MediumBold";         
        font-weight: 200 !important;
    }
    .fontMedium {
        font-family: "pop-Medium";       
        font-weight: 100;
    }
    .fontBold {
        font-family: "pop-Bold";        
    }
    .fontRegular {
        font-family: "pop-Regular";         
    }
}
.fontRegular {
    font-family: "pop-Regular";    
}

.fontBold {
    font-family: "pop-Bold";   
}

 .custom-font-md {
    font-family: "pop-MediumBold";
    font-weight: 200;
}