footer {
  background-color: #1b263b;
  padding: 3em 4em 2em;
  background-image: url("../../Assets/icons/Rectangle\ 149.png"),
    url("../../Assets/icons/Rectangle\ 156.png");
  background-repeat: none;
  background-position: center, center;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  .MainDiv1 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }
  .MainInnerDiv1 {
    img {
      height: auto;
      min-width: 170px;
    }
    p {
      color: white;
      text-align: left;
      max-width: 300px;
    }
  }
  .MainInnerDiv2 {
    display: flex;

    flex-direction: column;
    span {
      color: white;
      margin-top: 20px;
    }
    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin-top: 5px;
        a {
          color: white;
          text-decoration: none;
          &:hover {
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }
  .MainInnerDiv3 {
    display: flex;
    flex-direction: column;
    span {
      color: white;
      margin-top: 20px;
    }
    ul {
      padding-left: 0;
      list-style: none;
      li {
        margin-top: 5px;
        a {
          color: white;
          text-decoration: none;
          &:hover {
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }
  .MainInnerDiv4 {
    margin-top: 20px;
    .MainInnerDiv41 {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: white;
        margin-bottom: 20px;
      }
      img {
        max-width: 100px;
        margin: 10px 15px;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .MainInnerDiv42 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
      span {
        color: white;
        margin-bottom: 20px;
      }
      a {
        img {
          width: 25px;
          margin: 0 4px;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .MainDiv2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
    .MainDiv21 {
      min-width: 350px;
      text-align: center;
      color: white;
    }
    .MainDiv22 {
      display: flex;
      @media screen and (max-width: 768px) {
        margin-top: 25px;
      }
      span {
        margin-right: 20px;
        color: white;
        a {
          color: white !important;
          &:hover {
            border-bottom: 2px solid white;
          }
        }
      }
    }
  }
}
.CookiesBox {
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: #2e71cb;
  padding: 10px;

  display: flex;
  align-items: baseline;
  justify-content: space-around;
  flex-wrap: wrap;
  color: white;
  p {
    font-size: 1rem;
    margin-left: 10px;
    a {
      color: white;
      border-bottom: 1px solid white;
    }
  }
  button {
    background-color: white;
    color: #2e71cb;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    padding: 5px;
  }
}
.Border100 {
  border: 1px solid rgb(83, 83, 83);
  width: 100%;
  margin-top: 20px;
}
.fontBold {
  font-size: 1.2rem;
}

.developBy{
  color: white;
  @media screen and (max-width:991px) {
    display: none;
  }
}
.developByMobile{
  color: white;
  margin-top: 25px;
  @media screen and (min-width:991px) {
    display: none;
  }
}