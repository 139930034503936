@import "./Styles/globle.scss";

html {
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none !important;
}

a:hover {
	color: none !important;
	text-decoration: none !important;
}

.route-container {
	position: relative;
}

@media (min-width: 1400px) {
	.container {
		max-width: 1360px;
	}
}

@media (min-width: 1300px) {
	.container {
		max-width: 1224px;
		padding-left: 0;
		padding-right: 0;
	}
	.container-fluid {
		padding-left: 16px;
		padding-right: 16px;
	}
}

@media (min-width: 1600px) {
	.footer-container {
		max-width: 1360px;
		padding-left: auto;
		padding-right: auto;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 1900px) {
	.footer-container {
		max-width: 1360px;
		padding-left: auto;
		padding-right: auto;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (max-width: 575px) {
	.container {
		padding-left: 16px;
		padding-right: 16px;
	}
	.container-fluid {
		padding-left: 16px;
		padding-right: 16px;
	}
	.row {
		margin-left: -8px;
		margin-right: -8px;
	}
	[class|="col"] {
		padding-left: 8px;
		padding-right: 8px;
	}
}

.styles-module_carousel-arrow__26sRw {
	background-color: aquamarine;
}
video::-webkit-media-controls, video::-moz-media-controls, video::-o-media-controls, video::-ms-media-controls {   display: none !important; }