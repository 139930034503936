///////  Main Globle Stylesheet

//==========================================================================================================

// RTL

$left: left;
$right: right;
$t-direction: -1;

//  custom Scroll
$scrollbar-thumb-width: 10px;
$scrollbar-thumb-color: rgb(29, 125, 235);
$scrollbar-track-color: rgba(39, 124, 235, 0);
