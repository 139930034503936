// input {
//   border-radius: 23px;
//   width: 100%;
//   height: 46px;
//   padding-left: 20px;
//   padding-right: 20px;
//   outline: none;
//   margin: 3px 0px;
//   border: 1px solid $input-border-color;
// }
// input::placeholder {
//   color: $placeholder-color;
//   font-size: 14px;
// }
// input:focus {
//   outline: none;
//   border: 1px solid $border-color;
// }
// / Chrome, Safari, Edge, Opera /
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// / Firefox /
input[type="number"] {
	-moz-appearance: textfield;
}

.journal-scroll,
body,
textarea {
	// Chrome & Opera
	&::-webkit-scrollbar {
		width: $scrollbar-thumb-width;

		&-track {
			background-color: $scrollbar-track-color;
		}

		&-thumb {
			background-color: $scrollbar-thumb-color;
		}

		&-track,
		&-thumb {
			border-radius: 12px;
			-webkit-border-radius: 12px;
			-moz-border-radius: 12px;
			-ms-border-radius: 12px;
			-o-border-radius: 12px;
		}
	}

	// Firefox
	scrollbar-width: thin;
	scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
}

.contact-us-data {
	form {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	input {
		height: 3.375rem;
		padding-left: 1.875rem;
		background-color: #f1f1f1;
		color: #415a77;
		border: none;
		&:focus {
			outline: 2px solid #2e71cb;
		}
	}
	textarea {
		height: 7.75rem;
		padding-left: 1.875rem;
		padding-top: 0.9375rem;
		color: #415a77;
		border: none;
		background-color: #f1f1f1;
		&:focus {
			outline: 2px solid #2e71cb;
		}
	}
	input[type="submit"] {
		background-color: #2e71cb;
		color: #ffffff;
		transition: all 0.5s;
		&:hover {
			box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
				rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
				rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
				rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
				rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
				rgba(0, 0, 0, 0.09) 0px 32px 16px;
		}
	}

	input::placeholder,
	textarea::placeholder {
		color: #415a77;
	}
}
